/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import Swiper, { Navigation, Pagination } from 'swiper';
import gsap from "gsap";
import CustomEase from "@lib/gsap-pro/CustomEase";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTo);

Swiper.use([Navigation, Pagination]);

/*
|
| Importing Components
|-----------------------
*/
// import customGoogleMap from '@components/custom-google-map.js';
// import MaterializeForm from '@components/materialize-form.js';
import Kira from '@components/kira.js';
import Menu from '@components/menu.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import home from '@pages/home.js';
import games from '@pages/games.js';
import about from '@pages/about.js';
/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, gsap, ScrollTrigger, Menu, Kira, CustomEase]
  },
  {
    'file': main,
    'dependencies': [app]
  },
  {
    'file': home,
    'dependencies': [app, Swiper, gsap],
    'resolve': '#page-home'
  },
  {
    'file': news,
    'dependencies': [app, Swiper, gsap],
    'resolve': '#page-news-archive'
  },
  {
    'file': games,
    'dependencies': [app, Swiper, gsap],
    'resolve': '#page-game-single'
  },
  {
    'file': about,
    'dependencies': [app, Swiper],
    'resolve': '#page-about'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

