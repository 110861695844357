/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, gsap) => {


        /*
        |
        | Videos
        |--------------
        */
        const gamesVideos = new Swiper('.single-game-videos .swiper', {
            loop: true,
            slidesPerView: 1.2,
            spaceBetween: 10,
            slidesPerGroup: 1,
            centeredSlides: true,
            slideToClickedSlide: true,
            breakpoints: {
                1024: {
                    slidesPerView: 1.5,
                    spaceBetween: 50,
                    // slidesPerGroup: 2,
                }
            },
            navigation: {
                nextEl: '.single-game-videos .navigation .next',
                prevEl: '.single-game-videos .navigation .prev',
            },
            pagination: {
                el: '.swiper-pagination',
            },
        });


        $('.video-wrapper').on("click", function (e) {

            let url = "https://www.youtube.com/embed/" + $(this).attr("data-id");
            let extrUrl = "?rel=0&controls=0&showinfo=0&autoplay=1";
            $(this).find("iframe").attr("src", url + extrUrl)
            gsap.to($(this).find(".preview:not(.noplay)"), { opacity: 0, pointerEvents: "none" })
        })


        gamesVideos.on('slideChange', function () {
            $(".video-wrapper iframe").removeAttr("src")
            gsap.to($(".video-wrapper .preview"), { opacity: 1, pointerEvents: "all" })
        });


    }
}
