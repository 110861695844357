/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body');



        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 50
            }, 1000);
        });

        $body.on('loaderEnd', () => console.log('ended'))
    }
}
