/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {


        /*
        |
        | Team
        |------------
        */
        new Swiper('.aboutTeam', {
            slidesPerView: 1,
            spaceBetween: 30,
            centerInsufficientSlides: true,
            breakpoints: {
                768: {
                    slidesPerView: 2.5
                },
                1024: {
                    slidesPerView: 3
                }
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });

    }
}
