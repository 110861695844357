/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, gsap) => {

        /*
        |
        | Constants
        |------------
        */
        const banner = $('.home-banner');

        /*
        |
        | Banner
        |------------
        */

        const homeSwiper = new Swiper('.homeSwiper', {
            loop: false,
            // autoplay: {
            //     delay: 3000,
            // },

            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                // renderBullet: function (index, className) {
                //     return '<span class="' + className + ' swiper-pagination-bullet--svg-animation"><svg width="26" height="26" viewBox="0 0 28 28"><circle class="svg__circle" cx="14" cy="14" r="12" fill="none" stroke-width="2"></circle><circle class="svg__circle-inner" cx="14" cy="14" r="6" stroke-width="3"></circle></svg></span>';
                // },
            },
            // on: {
            //     init: function (swiper) {
            //         $(".swiper-slide-active video").get(0).play();
            //     },
            // }
        });

        homeSwiper.on('slideChange', function () {
            console.log("change")
            $(".home-banner-video iframe").removeAttr("src")
            $(".swiper-slide-active video").get(0).load();
            // gsap.to($(".home-banner-video .preview"), { opacity: 1, pointerEvents: "all" })
        });

        $('.home-banner-video .preview').on("click", function (e) {

            gsap.to($(this), { opacity: 0, pointerEvents: "none" })

            if ($(this).parents('.youtube').length) {
                let url = "https://www.youtube.com/embed/" + $(this).attr("data-id");
                let extrUrl = "?rel=0&controls=0&showinfo=0&autoplay=1";
                $(this).next("iframe").attr("src", url + extrUrl)
            }
            else {
                $(this).parents().find("video").get(0).play();
            }

        })

        let videos = document.querySelectorAll("video");

        videos.forEach(video => {
            video.addEventListener("click", function () {
                if (video.paused == true) {
                    video.play();
                } else {
                    video.pause();
                }
            });
        });




        /*
        |
        | Games
        |------------
        */
        new Swiper('.home-games[data-id="1"] .homeGames', {
            slidesPerView: 1,
            spaceBetween: 30,
            centerInsufficientSlides: true,
            breakpoints: {
                768: {
                    slidesPerView: 1.5
                },
                1024: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 4
                },
                1400: {
                    slidesPerView: 5
                }
            },
            navigation: {
                nextEl: '.home-games[data-id="1"] .navigation .next',
                prevEl: '.home-games[data-id="1"] .navigation .prev',
            },
        });


        new Swiper('.home-games[data-id="2"] .homeGames', {
            slidesPerView: 1,
            spaceBetween: 30,
            centerInsufficientSlides: true,
            breakpoints: {
                768: {
                    slidesPerView: 1.5
                },
                1024: {
                    slidesPerView: 3
                },
                1200: {
                    slidesPerView: 4
                },
                1400: {
                    slidesPerView: 5
                }
            },
            navigation: {
                nextEl: '.home-games[data-id="2"] .navigation .next',
                prevEl: '.home-games[data-id="2"] .navigation .prev',
            },
        });

    }
}
